(function () {
	'use strict';
	angular
		.module('core', [
			'ngSilent',
			'ngAnimate',
			'common',
			'ui.bootstrap',
			'pascalprecht.translate',
			'colorpicker.module',
			'activation',
			'measuring',
			'crypt',
			'calendar',
			'sidebar',
			'header',
			'event',
			'uiSlider',
		])

		.constant('calendarOptions', {
			language: 'en',
			translations: {}, //This is being kept for backwards compaitibilty with old translations files but someday may be removed if we have a way to update those
			urlParams: (function () {
				var match,
					pl = /\+/g, // Regex for replacing addition symbol with a space
					search = /([^&=]+)=?([^&]*)/g,
					decode = function (s) {
						return decodeURIComponent(s.replace(pl, ' '));
					},
					query = window.location.search
						.substring(1)
						.replace(/^\//, ''); // Remove first character if it is a forward slash

				var urlParams = {};
				while ((match = search.exec(query))) {
					urlParams[decode(match[1])] = decode(match[2]);
				}
				return urlParams;
			})(),
		})
		.service('urlParameters', ['$location', urlParameters]);

	function urlParameters($location) {
		// Get url parameters
		var match,
			pl = /\+/g, // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) {
				return decodeURIComponent(s.replace(pl, ' '));
			},
			query = window.location.search.substring(1).replace(/^\//, ''); // Remove first character if it is a forward slash

		while ((match = search.exec(query))) {
			this[decode(match[1])] = decode(match[2]);
		}

		// Get url hash search parameters
		var searchParams = $location.search();

		for (var property in searchParams) {
			this[property] = searchParams[property];
		}
	}
})();
